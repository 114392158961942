import React, { useEffect, useState } from "react";

import { useSiteData } from "../SiteDataContext";

import LessonsRibbon from "./LessonsRibbon";
import HeroTg from "./HeroTg";
import { getLessonNumber } from "../lib";

function Hero() {
  const { siteData } = useSiteData();

  let linkhref = "/login";
  let availableLesson = getLessonNumber(siteData?.lessons);
  if (availableLesson) {
    linkhref = `/lesson/${availableLesson}`;
  }

  return (
    <main>
      <section className="w-full flex justify-center">
        <div className="container relative color-black px-1-25 md:px-0 pt-4-375 pb-12">
          <div className="md:mw-56-5">
            <h2 className="f-size-2 md:f-size-3-75 font-bold lh-2-7 fw-700 mb-12">
              {siteData.texts?.main_title?.title}
            </h2>

            <h3 className="f-size-1-25 md:f-size-1-5 lh-1-75 fw-700 mb-6">
              {siteData.texts?.how_to_speed_up?.title}
            </h3>

            <h3 className="f-size-0-875 md:f-size-1-125 lh-1-3125 fw-700 lt-m-0-01">
              {siteData.texts?.also_safely?.title}
            </h3>
          </div>
        </div>
      </section>

      <section className="w-full flex justify-center pb-12">
        <LessonsRibbon />
      </section>

      <section className="w-full flex justify-center pb-12">
        <div className="container relative flex justify-center md:gap-1-25 flex-col md:flex-row">
          <div className="md:w-1/2 bg-green md:br-1 px-1-25 md:px-0 md:p-3-75 pt-14 pb-6 text-white">
            <h3 className="pb-9 fw-700 f-size-2-625">
              {siteData.texts?.what_is_it_about?.title}
            </h3>
            <p
              className="fw-700 f-size-1-125 lh-1-8"
              dangerouslySetInnerHTML={{
                __html: siteData.texts?.what_is_it_about?.content,
              }}
            ></p>
          </div>
          <div className="md:w-1/2 bg-green md:br-1 px-1-25 md:px-0 md:p-3-75 pt-8 pb-6 text-white">
            <h3 className="pb-9 fw-700 f-size-2-625">
              {siteData.texts?.who_is_it_for?.title}
            </h3>
            <p
              className="fw-700 f-size-1-125 lh-1-8"
              dangerouslySetInnerHTML={{
                __html: siteData.texts?.who_is_it_for?.content,
              }}
            ></p>
          </div>
        </div>
      </section>

      <section className="w-full flex justify-center">
        <div className="container relative color-black p-12 text-center">
          <h3 className="fw-700 f-size-2 mb-3 md:mb-12 md:f-size-3-75">
            Маршрут
          </h3>
          <div className="flex items-center justify-center">
            <a
              href={linkhref}
              className="hidden md:block bg-green color-white step-button mb-10 text-center f-size-1-125 fw-700 flex justify-center items-center p-0-875 md:px-5 md:py-1-5625"
            >
              Сделать шаг
            </a>
          </div>
          <p className="fw-700 f-size-1-25 md:f-size-1-5 lh-1-75">
            {siteData.texts?.short_steps?.title}
          </p>
        </div>
      </section>

      <section className="w-full flex justify-center pb-12">
        <div className="container relative px-0-625 md:px-0">
          <div className="gap-[1.5rem] md:gap-y-[1.875rem] md:gap-x-[1.25rem] grid grid-cols-1 md:grid-cols-2">
            {siteData?.lessons?.map((item) => (
              <a
                key={item.id}
                className={`br-0-625 md:flex md:h-14-875rem ${
                  item.is_active ? "card-active" : "card-inactive"
                }`}
                href={item.anonymous ? "#" : `/lesson/${item.step_number}`}
              >
                <div className="mx-3-125 fw-300 hidden md:flex lg:w-11 items-center justify-center text-6xl lg:f-size-10-625 card-number">
                  0{item.step_number}
                </div>
                <div className="flex-1 p-5 md:pl-0 md:py-3-71875">
                  <p className="fw-700 f-size-1-25 lh-1-75 md:hidden card-step">
                    Шаг 0{item.step_number}
                  </p>
                  <p className="fw-700 f-size-2 word-break sm:text-base md:text-base md:text-2xl lh-1-75 mb-3 md:mb-6 card-title  break-all">
                    {item.title}
                  </p>
                  <p className="fw-400 f-size-1-125 lh-1-8 mb-6">
                    {item.subtitle}
                  </p>
                  <div className="flex justify-center md:hidden">
                    <span className="card-btn br-0-3125 w-10-625 h-2-5625 flex flex-center items-center justify-center fw-700 f-size-0-875 lh-1-3125">
                      Сделать шаг
                    </span>
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
      </section>

      <HeroTg />
    </main>
  );
}

export default Hero;
