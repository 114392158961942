import Header from "../components/Header";
import Hero from "../components/Hero";
import Footer from "../components/Footer";

function Main() {
  return (
    <div className="bg-white-blue main-bg">
      <Header />
      <Hero />
      <Footer />
    </div>
  );
}

export default Main;
