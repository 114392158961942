import Header from "../components/Header";
import Footer from "../components/Footer";
import { useSiteData } from "../SiteDataContext";

function Privacy() {
  const { siteData } = useSiteData();

  return (
    <div className="bg-white-blue main-bg">
      <Header />
      <main>
        <section className="w-full flex justify-center">
          <div className="container relative color-black px-1-25 md:px-0 pt-4-375 pb-12">
            <div className="md:mw-56-5">
              <h3 className="f-size-1-25 md:f-size-1-5 lh-1-75 fw-700 mb-6">
                {siteData.texts?.privacy_policy?.title}
              </h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: siteData.texts?.privacy_policy?.content,
                }}
              ></div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Privacy;
