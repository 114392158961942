import React, { useEffect, useState } from "react";

import passwordIcon from "../assets/img/icons/passwordIcon.svg";
import passwordRedIcon from "../assets/img/icons/passwordRedIcon.svg";
import userIcon from "../assets/img/icons/userIcon.svg";
import userRedIcon from "../assets/img/icons/userRedIcon.svg";
import showIcon from "../assets/img/icons/showIcon.svg";
import showRedIcon from "../assets/img/icons/showRedIcon.svg";
import showDisabledIcon from "../assets/img/icons/showDisabledIcon.svg";
import showDisabledRedIcon from "../assets/img/icons/showDisabledRedIcon.svg";

function AuthComponent({
  title = "Вход",
  subTitle = "Авторизуйся, используя свои логин и пароль",
  isFormError = false,
  usernameTitle = "",
  authMessage = "",
  defaultUsername = "",
  defaultPassword = "",
  isDisabledSubmit = false,
  additionalContent = (
    <div className="font-medium text-sm lh-1-2 text-center px-3 mt-8">
      Не помнишь данные для входа?{" "}
      <a href="#" className="underline color-green">
        Заведи новый аккаунт
      </a>
    </div>
  ),
  midContent = "",
  submit = () => {},
  handleFormIsChanged = () => {},
}) {
  //const { siteData, setSiteData } = useSiteData();

  const formDataTemplate = {
    username: "",
    password: "",
    passwordTitle: "Пароль",
    isPasswordVisible: false,
  };

  const [formData, setFormData] = useState({ ...formDataTemplate });

  const handleFormDataChange = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const onLoad = () => {
    if (defaultUsername) {
      handleFormDataChange("username", defaultUsername);
    }
    if (defaultPassword) {
      handleFormDataChange("password", defaultPassword);
    }
  };

  useEffect(() => {
    onLoad();
  }, [defaultUsername, defaultPassword]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    handleFormDataChange(name, value);
    handleFormIsChanged();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submit({ username: formData.username, password: formData.password });
  };

  const { isPasswordVisible, passwordTitle } = formData;

  let dynamicShowIcon = showIcon;
  if (isPasswordVisible) dynamicShowIcon = showDisabledIcon;
  if (isFormError) dynamicShowIcon = showRedIcon;
  if (isFormError && isPasswordVisible) dynamicShowIcon = showDisabledRedIcon;

  return (
    <main className="w-full md:mw-30 bg-none md:bg-white br-0-625 py-20 px-8 md:px-16">
      <h1 className="font-bold f-size-2 mb-10 md:mb-15">{title}</h1>
      <p className="font-medium text-sm mb-8">{subTitle}</p>

      {authMessage ? <p className="auth-message">{authMessage}</p> : null}

      <form method="post" onSubmit={handleSubmit}>
        <fieldset className="mb-8">
          <label
            htmlFor="username"
            className={`block font-medium text-sm mb-2 ${
              isFormError ? "color-red" : "color-green"
            }`}
          >
            {usernameTitle}
          </label>
          <div className="relative">
            <img
              src={isFormError ? userRedIcon : userIcon}
              alt="User icon"
              className="wh-1-875 left-2 absolute top-1/2 transform -translate-y-1/2"
            />
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleInputChange}
              className={`font-normal w-full p-3 pl-12 rounded-[10px] border-[1px] border-solid bg-white ${
                isFormError ? "color-red bc-red" : "bc-violet"
              }`}
              placeholder="Имя пользователя"
              required
            />
          </div>
        </fieldset>

        <fieldset className="mb-10 md:mb-15">
          <label
            htmlFor="password"
            className={`block font-medium text-sm mb-2 ${
              isFormError ? "color-red" : "color-green"
            }`}
          >
            {passwordTitle}
          </label>
          <div className="relative">
            <img
              src={isFormError ? passwordRedIcon : passwordIcon}
              alt="User icon"
              className="wh-1-875 left-2 absolute top-1/2 transform -translate-y-1/2"
            />

            <input
              type={isPasswordVisible ? "text" : "password"}
              id="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              className={`font-normal w-full p-3 pl-12 pr-12 rounded-[10px] border-[1px] border-solid bg-white ${
                isFormError ? "color-red bc-red" : "bc-violet"
              }`}
              placeholder="Пароль"
              required
            />
            <button
              type="button"
              aria-label="Hide or show password"
              className="cursor-pointer wh-1-875 right-2 absolute top-1/2 transform -translate-y-1/2"
              onClick={() =>
                handleFormDataChange("isPasswordVisible", !isPasswordVisible)
              }
            >
              <img src={dynamicShowIcon} alt="Toggle password visibility" />
            </button>
          </div>
        </fieldset>

        {midContent}

        <div className="flex justify-center items-center">
          <button
            type="submit"
            className="bg-green w-full mr-4 w-15-5 br-0-3125 font-bold text-white p-3-5"
            onClick={handleSubmit}
            disabled={isDisabledSubmit}
          >
            Далее
          </button>
        </div>

        {additionalContent}
      </form>
    </main>
  );
}

export default AuthComponent;
