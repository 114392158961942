import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useSiteData } from "../SiteDataContext";

import presentDarkIcon from "../assets/img/icons/presentDarkIcon.svg";
import presentWhiteIcon from "../assets/img/icons/presentWhiteIcon.svg";
import { getLessonNumber } from "../lib";


function LessonsRibbon() {
  const { siteData } = useSiteData();
  const { lessonId } = useParams();

  let linkhref = "/login";
  let availableLesson = getLessonNumber(siteData?.lessons)
  if (availableLesson) {
    linkhref = `/lesson/${availableLesson}`;
  }

  const authUsername = localStorage.getItem("auth_username", "");

  return (
    <>
      {!authUsername ? (
        <div className="flex justify-center items-center w-100p">
          <a
            href={linkhref}
            className="bg-green color-white step-button text-center f-size-1-125 fw-700 flex justify-center items-center p-0-875 md:px-5 md:py-1-5625"
          >
            Сделать шаг
          </a>
        </div>
      ) : (
        <div className="container grid grid-cols-2 md:flex md:flex-row cg-0-625 rg-0-75 px-5 md:p-5 md:br-0-9375 md:bg-white">
          {siteData?.lessons?.map((item, index) => {
            const isCurrent = item.step_number == lessonId;
            const isActive = item.is_active;            
            const isDisabled = availableLesson < item.step_number;

            return (
              <a
                href={!isDisabled ? `/lesson/${item.step_number}` : "#"}
                className={`${isActive ? "active" : "inactive"} ${isCurrent ? "current" : ""
                  } lesson-button md:w-7-55 step-button py-0-625 text-center f-size-1-125 fw-700 flex justify-center items-center`}
                key={index}
                disabled={isDisabled}
              >
                Шаг {item.step_number}
                {item.has_present ? (
                  <img
                    src={
                      isActive || isCurrent ? presentWhiteIcon : presentDarkIcon
                    }
                    className="ml-0-625 wh-1-21875"
                    alt="present"
                  />
                ) : null}
              </a>
            );
          })}

          <a
            href={linkhref}
            className="hidden md:block bg-green color-white step-button text-center f-size-1-125 fw-700 flex justify-center items-center ml-auto p-0-875 md:px-5 md:py-1-5625"
          >
            Сделать шаг
          </a>
        </div>
      )}
    </>
  );
}

export default LessonsRibbon;
