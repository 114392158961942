import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { REACT_APP_API_URL } from "../config";
import { generateAdjectiveColorNoun, generateRandomWords } from "../lib";

import AuthComponent from "../components/AuthComponent";

function NewUser() {
  const original_usernameTitle = "Логин";
  const [usernameTitle, setUsernameTitle] = useState(original_usernameTitle);
  const [isFormError, setIsFormError] = useState(false);

  const [defaultUsername, setDefaultUsername] = useState("");
  const [defaultPassword, setDefaultPassword] = useState("");
  const [isWrotedown, setIsWrotedown] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const onLoad = () => {
    const name = generateAdjectiveColorNoun();
    const password = generateRandomWords(4);

    setDefaultUsername(name);
    setDefaultPassword(password);
  };

  const handleCheckboxChange = (event) => {
    setIsWrotedown(event.target.checked);
  };

  useEffect(() => {
    onLoad();
  }, []);

  const handleFormIsChanged = () => {
    setIsFormError(false);
    setUsernameTitle(original_usernameTitle);
  };

  const registerUser = async ({ username, password }) => {
    const userData = {
      username: username,
      password: password,
    };

    try {
      const response = await axios.post(
        `${REACT_APP_API_URL}/api/register/`,
        userData
      );

      if (response.data.status === "success") {
        navigate("/login", { state: { message: response.data.message } });
      } else {
        throw new Error(
          `The response was not ok: ${response} ${response.statusText}`
        );
      }
    } catch (error) {
      let errorMessage = "Ошибка при регистрации";

      if (error.response) {
        console.error("Error response:", error.response.data);
        if (error.response.data.status === "error") {
          errorMessage = error.response.data.message;
        }
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error:", error.message);
      }

      setUsernameTitle(errorMessage);
      setIsFormError(true);
    }
  };

  return (
    <div className="mh-152 bg-white-blue flex justify-center items-center h-screen">
      <AuthComponent
        title="Внимательно прочти"
        usernameTitle={usernameTitle}
        isFormError={isFormError}
        handleFormIsChanged={handleFormIsChanged}
        defaultUsername={defaultUsername}
        defaultPassword={defaultPassword}
        subTitle="Запиши свои данные для входа. Их невозможно будет изменить или восстановить, чтобы гарантировать твою анонимность."
        midContent={
          <div className="flex items-center mb-8">
            <input
              id="wrotedown"
              type="checkbox"
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              checked={isWrotedown}
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor="wrotedown"
              className="ml-2 block text-sm text-gray-900 select-none"
            >
              Я записал данные для входа
            </label>
          </div>
        }
        isDisabledSubmit={!isWrotedown}
        additionalContent={
          <div className="font-medium text-sm lh-1-2 text-center px-3 mt-5">
            <div className="f-size-0-75 lh-1 mb-4">
              Создавая аккаунт, ты соглашаешься с{" "}
             
              <a href="/privacy" className="underline color-green">
                Политикой безопасности
              </a>
              .
            </div>

            <div className="f-size-0-875">
              Уже есть аккаунт?{" "}
              <a href="/login" className="underline color-green font-bold">
                Вход тут
              </a>
            </div>
          </div>
        }
        authMessage={location?.state?.message}
        submit={({ username, password }) => {
          const userData = {
            username: username,
            password: password,
          };
          registerUser(userData);
        }}
      />
    </div>
  );
}

export default NewUser;
