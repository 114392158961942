import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useSiteData } from "../SiteDataContext";
import Popup from "./Popup";

function Footer() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <header className="w-full bg-violet-dark text-white flex items-center justify-center">
      <div className="container p-6 md:p-0 flex flex-col md:justify-between items-center md:flex-row md:h-6-25 w-full gap-3">
        <div className="flex">
          <div className="font-bold f-size-0-875 md:f-size-1-125 mr-5">© Fundacja Stay Connected</div>
          <div className="font-bold f-size-0-875 md:f-size-1-125 underline cursor-pointer"
            onClick={() =>
              navigate('/privacy')
            }
          >Политика безопасности</div>
        </div>
        <div className="f-size-0-8125 md:f-size-1-125 md:font-bold">2024</div>
      </div>


    </header>
  );
}

export default Footer;
