import { useParams } from "react-router-dom";

import { useSiteData } from "../SiteDataContext";

import Header from "../components/Header";

function ThankYou() {
  const { reportId } = useParams();
  const { siteData } = useSiteData();

  return (
    <div className="main-body bg-cream md:pb-10">
      <Header />

      <main className="w-full">
        <section className="w-full flex justify-center">
          <div className="container pl-1-25 md:plr-3">
            <h2 className="text-center lt-0-03 f-size-1-5 fw-700 font-bold mb-2-5 mt-2-7 md:text-left md:lt-0-04 md:f-size-3-125 md:mt-5 md:mb-3-5">
              {siteData?.contacts?.title}
            </h2>

            <div
              className="ck-content"
              dangerouslySetInnerHTML={{ __html: siteData?.contacts?.content }}
            />
          </div>
        </section>
      </main>
    </div>
  );
}

export default ThankYou;
