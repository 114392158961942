import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";

import Main from "./pages/Main";
import Contacts from "./pages/Contacts";
import About from "./pages/About";

import { SiteDataProvider } from "./SiteDataContext";

import NewUser from "./pages/NewUser";
import Login from "./pages/Login";
import Lesson from "./pages/Lesson";
import { REACT_APP_API_URL } from "./config";
import axios from "axios";
import { clearStorage } from "./lib";
import Privacy from "./pages/Privacy";

function App() {
  const [siteData, setSiteData] = useState({});

  const getSiteTexts = () => {
    axios
      .get(`${REACT_APP_API_URL}/api/site-texts/`)
      .then((response) => {
        setSiteData((prevState) => ({ ...prevState, texts: response.data }));
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  };

  const updateLessons = () => {
    const authToken = localStorage.getItem("auth_token");

    axios
      .get(`${REACT_APP_API_URL}/api/lessons/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        setSiteData((prevState) => ({ ...prevState, lessons: response.data }));
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
        if (error.response?.status === 401) {
          clearStorage();
          window.location.reload();
        }
      });
  };

  const getLessons = () => {
    axios
      .get(`${REACT_APP_API_URL}/api/anonymous-lessons/`)
      .then((response) => {
        const lessons = response.data;
        lessons.map((item) => {
          item.anonymous = true;
          return item;
        });

        setSiteData((prevState) => ({ ...prevState, lessons: lessons }));
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
        if (error.response?.status === 401) {
        }
      });
  };

  useEffect(() => {
    const authToken = localStorage.getItem("auth_token");
    if (!authToken) {
      getLessons();
      return;
    }
  }, []);

  useEffect(() => {
    getSiteTexts();
  }, []);

  useEffect(() => {
    const authToken = localStorage.getItem("auth_token");
    if (!authToken) {
      clearStorage();
      return;
    }

    if (!siteData.lessons?.length) {
      updateLessons();
    }
  }, [siteData.username]);

  return (
    <SiteDataProvider siteData={siteData} setSiteData={setSiteData}>
      <Router>
        {/* <MobileMenu /> */}
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/login" element={<Login />} />
          <Route path="/new-user" element={<NewUser />} />
          <Route path="/about" element={<About />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/lesson/:lessonId" element={<Lesson />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </Router>
    </SiteDataProvider>
  );
}

export default App;
