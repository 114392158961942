import React from "react";

import { useSiteData } from "../SiteDataContext";
import closeIcon from "../assets/img/icons/closeIcon.svg";

function Popup({
  title = "",
  bonus = "",
  onClickPrimaryButton = () => { },
  onClickFirstButton = () => { },
  greenText = "",
  firstButtonText = "Продолжить позже",
  primaryButtonText = "Следующий урок",
  hidePrimaryBtn = false,
  onClosePopup = () => { },
}) {
  const { siteData, setSiteData } = useSiteData();

  return (
    <div
      id="congrats-popup"
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
    >
      <div className="bg-white rounded-lg shadow-lg p-8 md:p-[6.25rem] max-w-75 mx-auto bc-green bw-0-125 relative">
        <div
          className="inline absolute right-8 top-8 p-0-5 cursor-pointer"
          onClick={onClosePopup}
        >
          <img className="w-1-875" src={closeIcon} />
        </div>

        <h2 className="text-[2rem] md:text-[3.75rem] font-bold text-center color-green">
          {title}
        </h2>

        {greenText ? (
          <p className="text-[1.5rem] font-bold color-green">{greenText}</p>
        ) : (
          ""
        )}

        <div className="mb-8 md:mb-[3.75rem]"></div>

        {bonus ? (
          <div>
            {bonus}
          </div>
        ) : (
          ""
        )}

        <div className="flex flex-col mt-6 sm:flex-row justify-center gap-2 md:gap-[6.25rem]">
          {/* <button
            className="font-bold text-2xl px-[5rem] py-[1.5rem] color-black bg-white-blue text-white rounded focus:outline-none"
            onClick={onClickFirstButton}
          >
            {firstButtonText}
          </button> */}

          {!hidePrimaryBtn && (
            <button
              className="font-bold text-2xl px-[5rem] py-[1.5rem] color-white bg-violet-dark text-white rounded focus:outline-none"
              onClick={onClickPrimaryButton}
            >
              {primaryButtonText}
            </button>
          )}

        </div>
      </div>
    </div>
  );
}

export default Popup;
